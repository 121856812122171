import { Tag } from 'antd';

export const PAGINATION_CONFIG = {
  position: ['bottomCenter'],
};

export const REGEX_NAME = /^[a-zA-Z ]+$/;
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_URL =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

export const REGEX_NUMBER = /^\d+$/;
export const REGEX_ALL_NUMBER = /^[-+]?\d+(\.\d+)?$/;
export const dateFormat = 'MM/DD/YYYY';
export const storeDateFormat = 'YYYY-MM-DD';
export const dateFormatWithTime = 'MM/DD/YYYY HH:mm:ss';

export const defaultAvatarLetter = 'A';
export const CURRENCY = '$';

export const userType = {
  ADMIN: 'ADMIN',
  HOSPITAL: 'HOSPITAL',
  VENDOR: 'VENDOR',
};

export const approvalStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

// User status
export const userStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
export const userStatusLabelMap = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
export const userStatusColorMap = {
  INACTIVE: '',
  ACTIVE: 'success',
  PENDING: 'warning',
};

// User role
export const USER_ROLE = {
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  FACILITY_ACCOUNT_MANAGER: 'FACILITY_ACCOUNT_MANAGER',
  FACILITY_USER: 'FACILITY_USER',
  VENDOR_ACCOUNT_MANAGER: 'VENDOR_ACCOUNT_MANAGER',
  VENDOR_USER: 'VENDOR_USER',
  PROVIDER_EXECUTIVE_ADMIN: 'PROVIDER_EXECUTIVE_ADMIN',
  PROVIDER_USER: 'PROVIDER_USER',
  ACCOUNT_MANAGER_USER: 'ACCOUNT_MANAGER_USER',
  SUPER_VENDOR: 'SUPER_VENDOR',
};
export const roleToRoleDisplayName = {
  ACCOUNT_MANAGER: 'Super Admin',
  FACILITY_ACCOUNT_MANAGER: 'Facility Admin',
  FACILITY_USER: 'Facility User',
  VENDOR_ACCOUNT_MANAGER: 'Vendor Admin',
  VENDOR_USER: 'Vendor User',
  PROVIDER_EXECUTIVE_ADMIN: 'Provider Admin',
  PROVIDER_USER: 'Provider User',
  ACCOUNT_MANAGER_USER: 'Super Admin Team User',
  SUPER_VENDOR: 'Super Vendor',
};

// Order status
export const ORDER_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};
export const orderStatusLabelMap = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Process',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
};
export const orderStatusColorMap = {
  PENDING: 'processing',
  CANCELLED: 'error',
  COMPLETED: 'success',
  IN_PROGRESS: 'warning',
};
export const ORDER_SUB_STATUS = {
  NEW_ORDER: 'NEW_ORDER',
  VENDOR_ASSIGNED: 'VENDOR_ASSIGNED',
  VENDOR_CONFIRMED_RECEIPT: 'VENDOR_CONFIRMED_RECEIPT',
  VENDOR_DECLINED: 'VENDOR_DECLINED',
  FACILITY_CANCELLED: 'FACILITY_CANCELLED',
  ORDER_REQUESTED_FOR_MODIFY: 'ORDER_REQUESTED_FOR_MODIFY',
  PATIENT_VISITED_AND_ASSESSED: 'PATIENT_VISITED_AND_ASSESSED',
  DELIVERED: 'DELIVERED',
  PROOF_UPLOADED: 'PROOF_UPLOADED',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
};

// Invoice sub status
export const INVOICE_SUB_STATUS = {
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  SPEND_CONFIRMED: 'SPEND_CONFIRMED',
  INVOICE_GENERATED: 'INVOICE_GENERATED',
  INVOICE_SENT: 'INVOICE_SENT',
  INVOICE_PAID: 'INVOICE_PAID',
};
export const invoiceStatusLabelMap = {
  ORDER_COMPLETED: 'Unbilled',
  SPEND_CONFIRMED: 'Unbilled',
  INVOICE_GENERATED: 'Unbilled',
  INVOICE_SENT: 'Open',
  INVOICE_PAID: 'Paid',
};
export const billingColorMap = {
  ORDER_COMPLETED: 'unbilled-tag',
  SPEND_CONFIRMED: 'unbilled-tag',
  INVOICE_GENERATED: 'unbilled-tag',
  INVOICE_SENT: 'processing',
  INVOICE_PAID: 'success',
};

// Support ticket
export const TICKET_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};
export const ticketStatusLabelMap = {
  OPEN: 'Open',
  CLOSED: 'Closed',
};
export const ticketColorMap = {
  OPEN: 'processing',
  CLOSED: 'default-tag',
};

// Notifications
export const redirectTo = {
  ORDER: 'ORDER',
  INVOICE: 'INVOICE',
  FACILITY_USER: 'FACILITY_USER',
  VENDOR_USER: 'VENDOR_USER',
  ROOM: 'ROOM',
  SUPPORT_TICKET: 'SUPPORT_TICKET',
  NO_REDIRECT: 'NO_REDIRECT',
};

// OIG status
export const oigStatus = {
  CLEARED: 'CLEARED',
  EXCLUDED: 'EXCLUDED',
};

export const oigOptions = [
  {
    label: 'Cleared',
    value: 'CLEARED',
  },
  {
    label: 'Excluded',
    value: 'EXCLUDED',
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const relationshipToPatient = [
  { label: 'Self', value: 'Self' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Child', value: 'Child' },
  { label: 'Other', value: 'Other' },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const subjectOptions = [
  {
    label: 'Account Related Issue',
    value: 'ACCOUNT',
  },
  {
    label: 'Pricing Related Issue',
    value: 'PRICING',
  },
  {
    label: 'Order Related Issue',
    value: 'ORDER',
  },
  {
    label: 'Contract Related Issue',
    value: 'CONTRACT',
  },
  {
    label: 'Invoice Related Issue',
    value: 'INVOICE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const subscriptionPlanOptions = [
  {
    label: 'Basic',
    value: 'Basic',
    price: 100,
    users: 5,
  },
  {
    label: 'Professional',
    value: 'Professional',
    price: 200,
    users: 10,
  },
  {
    label: 'Enterprise',
    value: 'Enterprise',
    price: 500,
    users: 20,
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const billingCycleOptions = [
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const approvalStatusForVendors = (row) => {
  let defaultTag = '';
  let statusText = '';
  if (row?.approvalStatus === 'REJECTED') {
    defaultTag = 'error';
    statusText = 'Inactive';
  } else if (row?.approvalStatus === 'PENDING') {
    defaultTag = 'processing';
    statusText = 'Pending Approval';
  } else if (row?.approvalStatus === 'APPROVED') {
    defaultTag = 'success';
    statusText = 'Active';
  }
  return (
    <Tag key={row?.id} className="idea tag-font w-100" color={defaultTag}>
      {statusText}
    </Tag>
  );
};

export const hospitalRoleList = [
  {
    label: 'Facility User',
    value: USER_ROLE.FACILITY_USER,
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const vendorRoleList = [
  {
    label: 'Vendor User',
    value: USER_ROLE.VENDOR_USER,
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const adminRoleList = [
  {
    label: 'Admin User',
    value: USER_ROLE.ACCOUNT_MANAGER_USER,
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const statusList = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const tableCell = (text, isUppercase) => (
  <span className={`table-font ${isUppercase && `text-uppercase`}`}>
    {text || '-'}
  </span>
);

export const stateList = [
  { label: 'Alabama', abbreviation: 'AL', value: 'Alabama' },
  { label: 'Alaska', abbreviation: 'AK', value: 'Alaska' },
  { label: 'Arizona', abbreviation: 'AZ', value: 'Arizona' },
  { label: 'Arkansas', abbreviation: 'AR', value: 'Arkansas' },
  { label: 'California', abbreviation: 'CA', value: 'California' },
  { label: 'Colorado', abbreviation: 'CO', value: 'Colorado' },
  { label: 'Connecticut', abbreviation: 'CT', value: 'Connecticut' },
  { label: 'Delaware', abbreviation: 'DE', value: 'Delaware' },
  { label: 'Florida', abbreviation: 'FL', value: 'Florida' },
  { label: 'Georgia', abbreviation: 'GA', value: 'Georgia' },
  { label: 'Hawaii', abbreviation: 'HI', value: 'Hawaii' },
  { label: 'Idaho', abbreviation: 'ID', value: 'Idaho' },
  { label: 'Illinois', abbreviation: 'IL', value: 'Illinois' },
  { label: 'Indiana', abbreviation: 'IN', value: 'Indiana' },
  { label: 'Iowa', abbreviation: 'IA', value: 'Iowa' },
  { label: 'Kansas', abbreviation: 'KS', value: 'Kansas' },
  { label: 'Kentucky', abbreviation: 'KY', value: 'Kentucky' },
  { label: 'Louisiana', abbreviation: 'LA', value: 'Louisiana' },
  { label: 'Maine', abbreviation: 'ME', value: 'Maine' },
  { label: 'Maryland', abbreviation: 'MD', value: 'Maryland' },
  { label: 'Massachusetts', abbreviation: 'MA', value: 'Massachusetts' },
  { label: 'Michigan', abbreviation: 'MI', value: 'Michigan' },
  { label: 'Minnesota', abbreviation: 'MN', value: 'Minnesota' },
  { label: 'Mississippi', abbreviation: 'MS', value: 'Mississippi' },
  { label: 'Missouri', abbreviation: 'MO', value: 'Missouri' },
  { label: 'Montana', abbreviation: 'MT', value: 'Montana' },
  { label: 'Nebraska', abbreviation: 'NE', value: 'Nebraska' },
  { label: 'Nevada', abbreviation: 'NV', value: 'Nevada' },
  { label: 'New Hampshire', abbreviation: 'NH', value: 'New Hampshire' },
  { label: 'New Jersey', abbreviation: 'NJ', value: 'New Jersey' },
  { label: 'New Mexico', abbreviation: 'NM', value: 'New Mexico' },
  { label: 'New York', abbreviation: 'NY', value: 'New York' },
  { label: 'North Carolina', abbreviation: 'NC', value: 'North Carolina' },
  { label: 'North Dakota', abbreviation: 'ND', value: 'North Dakota' },
  { label: 'Ohio', abbreviation: 'OH', value: 'Ohio' },
  { label: 'Oklahoma', abbreviation: 'OK', value: 'Oklahoma' },
  { label: 'Oregon', abbreviation: 'OR', value: 'Oregon' },
  { label: 'Pennsylvania', abbreviation: 'PA', value: 'Pennsylvania' },
  { label: 'Rhode Island', abbreviation: 'RI', value: 'Rhode Island' },
  { label: 'South Carolina', abbreviation: 'SC', value: 'South Carolina' },
  { label: 'South Dakota', abbreviation: 'SD', value: 'South Dakota' },
  { label: 'Tennessee', abbreviation: 'TN', value: 'Tennessee' },
  { label: 'Texas', abbreviation: 'TX', value: 'Texas' },
  { label: 'Utah', abbreviation: 'UT', value: 'Utah' },
  { label: 'Vermont', abbreviation: 'VT', value: 'Vermont' },
  { label: 'Virginia', abbreviation: 'VA', value: 'Virginia' },
  { label: 'Washington', abbreviation: 'WA', value: 'Washington' },
  { label: 'West Virginia', abbreviation: 'WV', value: 'West Virginia' },
  { label: 'Wisconsin', abbreviation: 'WI', value: 'Wisconsin' },
  { label: 'Wyoming', abbreviation: 'WY', value: 'Wyoming' },
].map((relation) => ({ ...relation, label: relation.label.toUpperCase() }));

export const defaultPermission = {
  linkAccount: false,
  createSupplyOrder: false,
  inviteVendor: false,
  assignVendor: false,
  viewSubUser: false,
  editSubUser: false,
  createSubUser: false,
};

export const rolePermission = (currentRole) => {
  const returnObj = {
    ACCOUNT_MANAGER: {
      ...defaultPermission,
      createSubUser: true,
      editSubUser: true,
    },
    FACILITY_ACCOUNT_MANAGER: {
      ...defaultPermission,
      linkAccount: true,
      createSupplyOrder: true,
      inviteVendor: true,
      assignVendor: true,
      viewSubUser: true,
      editSubUser: true,
      createSubUser: true,
    },
    FACILITY_USER: {
      ...defaultPermission,
      createSupplyOrder: true,
      inviteVendor: true,
      assignVendor: true,
    },
    VENDOR_ACCOUNT_MANAGER: {
      ...defaultPermission,
      viewSubUser: true,
      editSubUser: true,
      createSubUser: true,
    },
    VENDOR_USER: {
      ...defaultPermission,
    },
    PROVIDER_EXECUTIVE_ADMIN: {
      ...defaultPermission,
      createSubUser: true,
      editSubUser: true,
    },
    PROVIDER_USER: {
      ...defaultPermission,
    },
    ACCOUNT_MANAGER_USER: {
      ...defaultPermission,
    },
  };
  return currentRole ? returnObj[currentRole] : null;
};

export const defaultNotiProvider = {
  newOrder: {
    inApp: true,
    email: false,
  },
  vendorConfirmedReceipt: {
    inApp: true,
    email: false,
  },
  vendorDeclined: {
    inApp: true,
    email: false,
  },
  orderCompleted: {
    inApp: true,
    email: false,
  },
  invoiceGenerated: {
    inApp: true,
    email: false,
  },
  chat: {
    inApp: true,
    email: false,
  },
  orderRequestedForModify: {
    inApp: true,
    email: false,
  },
  totalTimeDelay: {
    inApp: true,
    email: false,
  },
  invoiceModificationRequested: {
    inApp: true,
    email: false,
  },
  supportTicketReplies: {
    inApp: true,
    email: false,
  },
};

export const defaultNotiVendor = {
  vendorAssigned: {
    inApp: true,
    email: false,
  },
  chat: {
    inApp: true,
    email: false,
  },
  supportTicketReplies: {
    inApp: true,
    email: false,
  },
};

export const defaultNotiAdmin = {
  invoiceModificationRequested: {
    inApp: true,
    email: false,
  },
  newUserCreated: {
    inApp: true,
    email: false,
  },
  userSettingsChanged: {
    inApp: true,
    email: false,
  },
  helpdeskTicketSubmitted: {
    inApp: true,
    email: false,
  },
};

export const defaultNotiSuperVendor = {
  supportTicketReplies: {
    inApp: false,
    email: false,
  },
};

export const NOMFA_EXTENSION = ['yopmail.com'];
